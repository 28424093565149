<template>
  <div style="position:relative;">
    <el-button class="btn" type="primary" @click="back" v-if="showContent">返回</el-button>
    <div class="join" v-if="!showContent">
      <div v-for="item in joinList" class="join_box">
        <div class="join_name">{{ item.name }}</div>
        <img :src="item.imgUrl" @click="showDetail(item.id)"/>
      </div>
    </div>
    <div v-if="showContent && (id ===1)" class="">
      <div class="tab">
      </div>
      <div class="content-box flex">
        <div class="name">{{ item1.name }}</div>
        <div class="flex item-box">
          <section class="left">
            <div class="skill">职位要求：</div>
            <div class="item flex" v-for="(obj,index) in item1.skill">
              <div>{{ index + 1 }}、{{ obj }}</div>
            </div>
          </section>
          <section class="right">
            <div class="skill">任职要求：</div>
            <div class="item" v-for="obj in item1.ask">{{ obj }}</div>
          </section>
        </div>
      </div>
    </div>
    <div v-if="showContent && (id ===2)" class="">
      <div class="tab">
      </div>
      <div class="content-box flex">
        <div class="name">{{ item2.name }}</div>
        <div class="flex item-box">
          <section class="left">
            <div class="skill">职位要求：</div>
            <div class="item flex" v-for="(obj,index) in item2.skill">
              <div>{{ index + 1 }}、 {{ obj }}</div>
            </div>
          </section>
          <section class="right">
            <div class="skill">任职要求：</div>
            <div class="item" v-for="obj in item2.ask">{{ obj }}</div>
          </section>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "join-us",
  data() {
    return {
      id: null,
      joinList: [
        {
          id: 1,
          name: "研发团队",
          imgUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/join1.png",
        },
        {
          id: 2,
          name: "项目团队",
          imgUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/join2.png",
        },
      ],
      item1: {
        name: "研发总监",
        skill: [
          "参与公司战略制定，全面负责公司前端研发层面的所有管理工作，把控公司技术研发整体发展方向；",
          "负责制定研发团队的目标和工作计划，并督促高效执行，确保研发目标实现；",
          "组织制定和实施重大技术决策和技术方案，制定技术发展战略、规划发展方向；",
          "负责技术攻关与团队技术指导;带领研发团队完成平台整体搭建、维护及产品开发。",
        ],
        ask: [
          "1、985/211软件、计算机、通信等相关专业研究生毕业；",
          "2、有三十人以上研发团队管理经验；",
          "3、具备研发项目从0-1到放大生产到商业化全流程的成功案例",
          "4、有较强的学术背景者（论文发表或专利申报）优先，有研发成果成功商业化经验者优先。",
        ],
      },
      item2: {
        name: "项目总监",
        skill: [
          "负责带领团队进行新商机发掘和售前业务拓展；",
          "根据项目情况，制定合理、可控的项目实施方案确定项目工作内容，制定和执行项目进度计划，对项目进度进行控制和管理；",
          "负责项目实施过程中新增、变更及客户定制需求的收集和整理；",
          "对项目进展情况进行定期分析和汇总，协助团队完成项目验收；",
          "项目结束后，组织项目团队进行项目复盘分析，收集项目经验和整理改进措施。",
        ],
        ask: [
          "1、985/211计算机、软件工程相关专业全日制本科毕业学历，具有项目五年以上管理工作经验和PMP证书;",
          "2、熟悉政务主流系统应用，具有MES相关生产系统实施经验；",
          "3、具备沟通和协作能力，性格稳重，责任心强，逻辑思维清晰，应变能力强;",
          "4、具有项目协调能力，独立分析解决问题的能力和抗压能力强。",
        ],
      },

      showContent: false,
    };
  },
  methods: {
    showDetail(id) {
      this.showContent = true
      this.id = id
      console.log(id);
    },
    back() {
      this.showContent = !this.showContent
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .btn {
    width: 113px;
    height: 47px;
    background: #217EFF;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 23px;
    position: absolute;
    top: -65px;
    right: 0;
  }
}

.join {
  padding: 0 180px;
  display: flex;
  justify-content: space-around;

  img {
    width: 427px;
    height: 337px;
    border-radius: 8px 8px 8px 8px;
  }


}

.icon1 {
  width: 30px;
  margin-right: 5px;
}

.content-box {
  //height: 269px;
  line-height: 20px;
  border-radius: 10px;
  //background-color: rgba(255, 255, 255, 1);
  //box-shadow: 5px 5px 5px 0px rgba(52, 111, 255, 0.2);
  //border: 1px solid rgba(52, 111, 255, 0.1);
  padding: 15px;
  margin-bottom: 25px;

  .name {
    width: 164px;
    height: 52px;
    line-height: 52px;
    flex-grow: 0;
    background: url("~@/assets/contact-us.png");
    background-size: contain;
    color: #333;
    font-size: 18px;
    text-align: right;
    font-weight: bold;
    margin-bottom: 15px;
    padding-right: 20px;
    margin-right: 44px;
  }

  .item-box {
    text-align: left;

    .left,
    .right {
      width: 47%;

      .skill {
        color: #333;
        font-size: 14px;
        text-indent: 16px;
        line-height: 40px;
        padding-left: 8px;

        &:before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #333;
          margin-right: 10px;
          position: relative;
          top: -2px;
        }
      }

      .item {
        color: #333;
        font-size: 14px;
        padding-left: 14px;
        line-height: 40px;
      }
    }

    .right {
      padding-left: 48px;
      width: 40%;
    }
  }
}

.join_box {
  position: relative;
}

.join_name {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  line-height: 26px;
  position: absolute;
  top: 30px;
  transform: translate(-50%);
  left: 50%;
}
</style>

<template>
  <div id="vital1">
    <div class="index1">
      <div class="box1-title">
        <div class="font">关于我们</div>
        <div class="border"></div>
      </div>

      <div class="text">
        金华市天华网络科技有限公司成立于2007年，公司主要从事信息行业应用软件开发和技术服务，大数据互联网与物联网、人工智能、云计算、AI等技术，开发大数据综合管理服务云平台。
      </div>
    </div>

    <div class="index4">
      <div class="index2">
        <div class="flex flex-direction-row tab">
          <div
              class="tab-item flex flex-align-center flex-justify-content"
              @click="tabChange(item, index)"
              v-for="(item, index) in tabList"
              :key="index"
              :class="activeTab === index ? 'active' : ''"
          >
            <!-- <img v-if="activeTab !== index" :src="item.icon1" alt="" />
          <img v-else :src="item.icon2" alt="" /> -->
            <span>{{ item.name }}</span>
          </div>
        </div>
        <AboutTianHua v-if="activeTab === 0"></AboutTianHua>
        <!-- <Culture v-if="activeTab === 1"></Culture> -->
        <Honor v-if="activeTab === 1"></Honor>
        <!-- <Soft v-if="activeTab === 3"></Soft> -->
        <ServiceProcess v-if="activeTab === 2"></ServiceProcess>
        <JoinUs v-if="activeTab === 3"></JoinUs>
      </div>
      <div class="culture-box">
        <div class="title">企业文化/Corporate culture</div>
      </div>

      <!-- <img
        src="https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/culture.png"
        class="culture_img"
      /> -->
    </div>
  </div>
</template>

<script>
import AboutTianHua from "@/views/about/aboutus/components/aboutTianHua.vue";
import Culture from "@/views/about/aboutus/components/culture.vue";
import Honor from "@/views/about/aboutus/components/honor.vue";
import Soft from "@/views/about/aboutus/components/soft.vue";
import ServiceProcess from "@/views/about/aboutus/components/service-process.vue";
import JoinUs from "@/views/about/aboutus/components/join-us.vue";

export default {
  name: "aboutUs",
  components: {
    AboutTianHua,
    Culture,
    Honor,
    Soft,
    ServiceProcess,
    JoinUs,
  },
  data() {
    return {
      activeTab: this.$route.query.activeTab || 0,
      directionList: [
        "https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/direction11.png",
        "https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/direction22.png",
        "https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/direction33.png",
        "https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/direction44.png",
      ],
      tabList: [
        {
          name: "关于天华",
          value: 0,
          icon1: require("@/assets/aboutus/tab-one-unactive.png"),
          icon2: require("@/assets/aboutus/tab-one-active.png"),
        },

        {
          name: "资质荣誉",
          value: 1,
          icon1: require("@/assets/aboutus/tab-three-unactive.png"),
          icon2: require("@/assets/aboutus/tab-three-active.png"),
        },

        {
          name: "服务流程",
          value: 2,
          icon1: require("@/assets/aboutus/tab-five-unactive.png"),
          icon2: require("@/assets/aboutus/tab-five-active.png"),
        },
        {
          name: "加入我们",
          value: 3,
          icon1: require("@/assets/aboutus/tab-six-unactive.png"),
          icon2: require("@/assets/aboutus/tab-six-active.png"),
        },
      ],
    };
  },
  watch: {
    "$route.query.activeTab": {
      handler(newVal) {
        if (newVal) {
          this.activeTab = newVal;
        } else {
          this.activeTab = 0;
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    tabChange(item, index) {
      this.activeTab = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.index1 {
  height: 610px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/news-banner.png") no-repeat center center;
  background-size: 1920px 609px;
  text-align: left;
  padding-left: 210px;
  padding-right: 210px;
}

.box1-title {
  padding-top: 155px;
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  line-height: 33px;

  .font {
    position: relative;
    z-index: 10;
  }
}

.border {
  width: 93px;
  height: 10px;
  background: linear-gradient(270deg, #014dd6 0%, rgba(1, 77, 214, 0) 100%);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-left: 15px;
  margin-top: -5px;
  position: relative;
  z-index: 9;
  right: -16px;
  bottom: 0px;
}

.text {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 23px;
  text-indent: 2em;

}

.index2 {
  margin: -680px 0 50px -750px;
  box-sizing: border-box;
  width: 1500px;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  padding: 33px 40px 33px 40px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 6px 11px 4px rgba(32, 89, 192, 0.07);
  position: relative;
  left: 50%;
  z-index: 10;

  .tab {
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 45px;
    line-height: 23px;

    .tab-item {
      color: #666;
      margin-right: 30px;
      user-select: none;
      padding-bottom: 6px;

      img {
        width: 25px;
        vertical-align: middle;
        margin-right: 5px;
      }

      &.active {
        color: rgba(52, 111, 255, 1);
        font-weight: bold;
        border-bottom: 2px solid #217eff;
      }
    }
  }
}

.index3 {
  width: 100%;
  height: 525px;
  background: #f4f9ff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 80px 240px 60px 240px;
  box-sizing: border-box;
}

.index4 {
  width: 100%;
  //height: 1013px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 350px 0 60px 0;
  box-sizing: border-box;
  background: url("~@/assets/aboutus/index2-bg.png") no-repeat center center;
  background-size: 100% 100%;
}

.culture-box {
  width: 100%;
  //height: 938px;
  height: 680px;
  opacity: 1;
  padding: 60px 240px 60px 240px;
  box-sizing: border-box;
  background: url("~@/assets/aboutus/index-bg.png") no-repeat;
  background-size: cover;
}

.title {
  font-size: 42px;
  font-weight: 400;
  color: #333333;
  line-height: 49px;
}

#vital1 {
  background: #ffffff;
  //overflow: auto;
}

.direction_img {
  width: 310px;
  height: 260px;
  margin-top: 60px;
}

.culture_img {
  width: 100%;
  margin-top: 60px;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>

<template>
  <div class="system">
    <div class="rule mb10" v-for="item in ruleItem">
      {{ item }}
    </div>
    <div class="flex item-box flex-between">
      <div class="item" v-for="(item, index) in itemList" :key="index">
        <!-- <img :src="item.icon" alt=""/> -->
        <div class="item-title">{{ item.title }}</div>
        <div v-for="obj in item.contentList">
          <div class="contentTitle">{{ obj.contentTitle }}</div>
          <div class="item-info">
            {{ obj.info }}
          </div>
          <div class="item-line" v-for="o in obj.list">
            {{ o }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "system-page",
  data() {
    return {
      ruleItem: [
        "以客户为中心，为客户创造价值；",
        "提供“专业、智能、高效”的技术及运营支持服务模式；",
        "基于互联网拓展便捷的服务手段与客户一同在变革的浪潮中创新发展；",
      ],
      itemList: [
        {
          icon: require("@/assets/aboutus/system-icon1.png"),
          title: "解决方案",
          contentList: [
            // {
            //   contentTitle: "建立阶段内容",
            //   info: "项目组织、需求调研、培训、实施、系统切换、运行维护",
            // },
            {
              contentTitle: "提供软件咨询服务",
              list: [
                "项目软件咨询",
                "产品+解决方案",
                "软件定制解决方案",
              ],
            },
          ],
        },
        {
          icon: require("@/assets/aboutus/system-icon2.png"),
          title: "平台运营",
          contentList: [
            {
              contentTitle: "提供项目运维服务",
              list: ["数据信息服务", "24h响应"],
            },
          ],
        },
        {
          icon: require("@/assets/aboutus/system-icon4.png"),
          title: "管理咨询",
          contentList: [
            {
              contentTitle: "提供管理咨询服务",
              list: [
                "系统解决方案",
                "调研支持",
                "项目认证支持",
              ],
            },
          ],
        },
        {
          icon: require("@/assets/aboutus/system-icon5.png"),
          title: "培训及认证服务",
          contentList: [
            {
              contentTitle: "提供培训及考试认证服务",
              list: [
                "企业管理运营",
                "电子商务应用等课程的培训",
                "考试认证服务",
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.system {
  margin-bottom: 113px;

  .mb10 {
    //margin-bottom: 10px;
  }

  .rule {
    color: rgba(16, 16, 16, 1);
    font-size: 18px;
    text-align: left;
    position: relative;
    line-height: 40px;
    padding-left: 14px;

    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba(52, 111, 255, 1);
      position: absolute;
      left: 0;
      top: 15px;
    }
  }

  .item-box {
    padding-top: 20px;

    .item {
      box-sizing: border-box;
      width: 274px;
      height: 258px;
      background: url("~@/assets/aboutus/about-box.png") no-repeat;
      background-size: 100% 100%;
      padding: 35px 11px 11px 14px;
      margin-right: 10px;

      .item-title {
        color: rgba(51, 51, 51, 1);
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 20px;
      }

      .contentTitle {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        text-align: left;
        margin-bottom: 10px;
        padding-left: 4px;

        &:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(52, 111, 255, 1);
          margin-right: 5px;
          position: relative;
          top: -4px;
        }
      }

      .item-info {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px;
        padding-left: 14px;
        line-height: 20px;
      }

      .item-line {
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px;
        padding-left: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>

<template>
  <div class="culture">
    <div class="flex item-box">
      <div class="item">
        <img src="~@/assets/aboutus/culture-item-one.png" alt=""/>
        <div class="rule-title">企业管理理念</div>
        <div class="rule" v-for="item in rule1">
          {{ item }}
        </div>
      </div>
      <div class="item">
        <img src="~@/assets/aboutus/culture-item-two.png" alt=""/>
        <div class="rule-title">企业宗旨</div>
        <div class="rule" v-for="item in rule2">
          {{ item }}
        </div>
      </div>
      <div class="item">
        <img src="~@/assets/aboutus/culture-item-three.png" alt=""/>
        <div class="rule-title">企业精神</div>
        <div class="rule" v-for="item in rule3">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "culture",
  data() {
    return {
      rule1: ['以良好的信誉为基础', '以精湛的技术为先导', '以优质的服务为根本', '以共赢的目标为追求', '以人为本，团结和谐，敬业负责'],
      rule2: ['诚信务实  开拓创新', '合作发展  服务社会'],
      rule3: ['创新发展理念  传承诚信文化', '打造百年品牌  培育时代精英'],
    }
  }
}
</script>

<style lang="scss" scoped>
.culture {
  margin: 0 auto;

  .item-box {
    justify-content: space-around;
    .item {
      width: 330px;
      height: 510px;
      line-height: 20px;
      border-radius: 10px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      box-shadow: 5px 5px 5px 0px rgba(52, 111, 255, .2);
      border: 1px solid rgba(52, 111, 255, 0.1);
      padding: 30px 40px;

      img {
        width: 240px;
        margin-bottom: 15px;
      }

      .rule-title {
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        text-align: left;
        margin-bottom: 15px;
        font-weight: bold;
        text-indent: 15px;
      }

      .rule {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        text-align: left;
        margin-bottom: 15px;

        &:before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba(52, 111, 255, 1);
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="system">
    <div class="flex item-box">
      <div class="item" v-for="(item, index) in itemList" :key="index">
        <!-- <img :src="item.icon" alt="" /> -->
        <div class="item-title">{{ item.title }}</div>
        <div class="contentTitle flex" v-for="obj in item.ruleList">
          <div class="round"></div>
          <div class="content">{{ obj }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "system-page",
  data() {
    return {
      itemList: [
        {
          icon: require("@/assets/aboutus/team-icon1.png"),
          title: "项目团队",
          ruleList: ['项目团队汇集了行业内顶尖人才，以卓越、创新和专业为价值观，为客户提供一流的解决方案。'],
        },
        {
          icon: require("@/assets/aboutus/team-icon2.png"),
          title: "研发团队",
          ruleList: [
            '研发团队由10年以上研发经验总监带领，团队成员均来自北上广深等地知名企业高端技术人才。'
          ],
        },
        {
          icon: require("@/assets/aboutus/team-icon3.png"),
          title: "服务团队",
          ruleList: [
            '服务团队是由行业内经验丰富的小组人员组成，团队可实现24h人工在线等多元化服务。'
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.system {
  width: 100%;
  margin-bottom: 75px;

  .mb10 {
    margin-bottom: 10px;
  }

  .rule {
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: left;
    position: relative;
    line-height: 24px;
    padding-left: 14px;

    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba(52, 111, 255, 1);
      position: absolute;
      left: 0;
      top: 7px;
    }
  }

  .item-box {
    // justify-content: space-around;

    .item {
      box-sizing: border-box;
      width: 251px;
      height: 215px;
      background: url("~@/assets/aboutus/about-box.png") no-repeat;
      background-size: 100% 100%;
      padding: 35px 15px 30px;
      margin-right: 30px;

      img {
        width: 160px;
        margin-bottom: 15px;
      }

      .item-title {
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .contentTitle {
        margin-bottom: 15px;
        align-items: baseline;

        //.round {
        //  width: 4px;
        //  height: 4px;
        //  border-radius: 50%;
        //  background-color: rgba(52, 111, 255, 1);
        //  margin-right: 5px;
        //  flex-shrink: 0;
        //  position: relative;
        //  top: -4px;
        //}

        .content {
          color: rgba(102, 102, 102, 1);
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          text-indent: 2em;
        }
      }
    }
  }
}
</style>

<template>
  <div class="container flex flex-between">
    <img
        class="banner"
        src="https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/company.png"
        alt=""
    />
    <div class="info">
      <div class="desc-title">公司简介</div>
      <div class="info-text-box">
        金华市天华网络科技有限公司成立于2007年，已在人工智能、大数据信息领域开拓16余年，获得国家、省级多项荣誉。
      </div>
      <div class="info-text-box">
        公司主要从事信息行业应用软件开发和技术服务，大数据互联网与物联网、人工智能、云计算、AI等技术，开发大数据综合管理服务云平台。
      </div>
      <div class="info-text-box">
        公司致力于政务项目，为用户提供完整的信息化解决方案，量身研发对应需求的综合服务平台，实现大数据管理、业务支撑和综合监管功能合一。
      </div>
      <div class="info-text-box">
        业务涵盖云数据中心、云服务大数据、智慧城市、智慧企业等业务板块，形成了覆盖基础设施、平台软件、数据信息和应用软件四个层面的整体解决方案服务能力，
        通过本方案全力打造服务满意数字政府、治理创新数字政府、效能便捷数字政府、赋能特色数字政府型。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutTianHua",
  data() {
    return {
      list: ["基础设施", "平台软件", "数据信息", "应用软件"],
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  //min-height: 510px;
  margin: 0 auto;

  .banner {
    width: 635px;
    height: 420px;
    margin-right: 30px;
    // marbin-bottom: 30px;
  }

  h1 {
    color: rgba(5, 6, 10, 1);
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 15px;
  }

  .info {
    display: flex;
    flex-direction: column;
    text-align: left;

    .info-text-box {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 40px;
      text-indent: 2em;
    }

    .desc-title {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      line-height: 38px;
      margin-bottom: 50px;
    }

    .business {
      width: 175px;
      height: 50px;
      flex-shrink: 0;
      margin-right: 20px;
      margin-bottom: 22px;
      line-height: 50px;
      font-size: 20px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
      text-align: center;
      box-shadow: 3px 3px 0px 0px rgba(52, 111, 255, 0.2);
      border: 1px solid rgba(52, 111, 255, 0.1);
    }
  }
}
</style>

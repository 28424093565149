<template>
  <div class="promise">
    <img src="~@/assets/aboutus/serve-promise.png"/>
    <!--    <img-->
    <!--      src="https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/safeguard.png"-->
    <!--    />-->
    <!-- <div class="item" v-for="(item,index) in list" :class="'item' + index">
      {{ item }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "promise",
  data() {
    return {
      list: [
        "网络安全",
        "服务器安全",
        "系统安全",
        "系统运行维护",
        "系统故障恢复",
        "系统配置调整",
        "技术咨询服务",
        "平台项目运营服务",
        "培训服务",
        "考试认证服务",
        "2h内响应",
        "24h内提供解决方案",
        "应急管理程序解决故障",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.promise {
  width: 865px;
  height: 450px;
  margin: 0 auto;
  // background: url("~@/assets/aboutus/promise-bg.png") no-repeat;
  // background-size: contain;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .item {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
  }

  .item0 {
    position: absolute;
    left: 52px;
    top: 48px;
  }

  .item1 {
    position: absolute;
    left: 48px;
    top: 114px;
  }

  .item2 {
    position: absolute;
    left: 52px;
    top: 178px;
  }

  .item3 {
    position: absolute;
    left: 40px;
    top: 300px;
  }

  .item4 {
    position: absolute;
    left: 40px;
    bottom: 110px;
  }

  .item5 {
    position: absolute;
    left: 40px;
    bottom: 46px;
  }

  .item6 {
    position: absolute;
    top: 16px;
    right: 38px;
  }

  .item7 {
    position: absolute;
    top: 80px;
    right: 24px;
  }

  .item8 {
    position: absolute;
    top: 145px;
    right: 52px;
  }

  .item9 {
    position: absolute;
    top: 210px;
    right: 40px;
  }

  .item10 {
    position: absolute;
    bottom: 176px;
    right: 50px;
  }

  .item11 {
    position: absolute;
    bottom: 110px;
    right: 20px;
  }

  .item12 {
    position: absolute;
    bottom: 46px;
    right: 10px;
  }
}
</style>

<template>
  <div class="system">
    <div class="flex item-box">
      <div class="item" v-for="(item, index) in itemList" :key="index">
        <!-- <img :src="item.icon" alt="" /> -->
        <div class="item-title">{{ item.title }}</div>
        <div class="contentTitle flex" v-for="obj in item.ruleList">
          <div class="round"></div>
          <div class="content">{{ obj }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "system-page",
  data() {
    return {
      itemList: [
        {
          icon: require("@/assets/aboutus/team-icon1.png"),
          title: "培训策略",
          ruleList: ["培训目的", "增加知识技能", "培训要素", "定制发言计划"],
        },
        {
          icon: require("@/assets/aboutus/team-icon2.png"),
          title: "培训过程",
          ruleList: ["培训准备", "分析配需需求", "培训实施", "确认培训地点"],
        },
        {
          icon: require("@/assets/aboutus/team-icon3.png"),
          title: "培训方式",
          ruleList: ["课堂培训", "开设培训课程", "在线指导", "网络在线"],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.system {
  width: 100%;
  margin-bottom: 75px;

  .mb10 {
    margin-bottom: 10px;
  }

  .rule {
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: left;
    position: relative;
    line-height: 24px;
    padding-left: 14px;

    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba(52, 111, 255, 1);
      position: absolute;
      left: 0;
      top: 7px;
    }
  }

  .item-box {
    // justify-content: space-around;

    .item {
      box-sizing: border-box;
      width: 251px;
      height: 226px;
      background: url("~@/assets/aboutus/about-box.png") no-repeat;
      background-size: 100% 100%;
      padding: 35px 11px 30px 30px;
      margin-right: 30px;

      img {
        width: 160px;
        margin-bottom: 15px;
      }

      .item-title {
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .contentTitle {
        margin-bottom: 15px;
        align-items: baseline;

        .round {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(52, 111, 255, 1);
          margin-right: 5px;
          flex-shrink: 0;
          position: relative;
          top: -4px;
        }

        .content {
          color: rgba(102, 102, 102, 1);
          font-size: 16px;
          text-align: left;
        }
      }
    }
  }
}
</style>

<template>
  <div class="service-process flex">
    <div class="flex flex-direction-column tab">
      <div
        class="tab-item flex flex-align-center flex-justify-content"
        @click="tabChange(item, index)"
        v-for="(item, index) in tabList"
        :key="index"
        :class="activeTab === index ? 'active' : ''"
      >
        <img :src="item.icon1" alt="" />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <SystemPage v-if="activeTab === 0"></SystemPage>
    <TeamPage v-if="activeTab === 1"></TeamPage>
    <PromisePage v-if="activeTab === 2"></PromisePage>
    <TrainPage v-if="activeTab === 3"></TrainPage>
  </div>
</template>

<script>
import SystemPage from "@/views/about/aboutus/components/service-process-components/system-page.vue";
import TeamPage from "@/views/about/aboutus/components/service-process-components/team-page.vue";
import PromisePage from "@/views/about/aboutus/components/service-process-components/promise-page.vue";
import TrainPage from "@/views/about/aboutus/components/service-process-components/train-page.vue";

export default {
  name: "service-process",
  components: {
    TeamPage,
    SystemPage,
    PromisePage,
    TrainPage,
  },
  data() {
    return {
      activeTab: 0,
      tabList: [
        {
          name: "服务体系",
          value: 0,
          icon1: require("@/assets/aboutus/service1.png"),
        },
        {
          name: "服务团队",
          value: 1,
          icon1: require("@/assets/aboutus/service2.png"),
        },
        {
          name: "服务保障",
          value: 2,
          icon1: require("@/assets/aboutus/service3.png"),
        },
        // {
        //   name: "客户培训",
        //   value: 3,
        //   icon1: require("@/assets/aboutus/service4.png"),
        // },
      ],
    };
  },
  methods: {
    tabChange(item, index) {
      document.documentElement.scrollTop = 0;
      this.activeTab = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-process {
  .tab {
    width: 160px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    margin-right: 40px;

    .tab-item {
      width: 154px;
      height: 56px;
      line-height: 20px;
      background-color: rgba(255, 255, 255, 1);
      text-align: center;
      box-shadow: 0px 0px 10px 0px rgba(52, 111, 255, 0.2);
      // border: 1px solid rgba(187, 187, 187, 1);
      color: #333333;
      margin-right: 20px;
      margin-bottom: 40px;
      user-select: none;
      border-radius: 8px 8px 8px 8px;
      font-size: 18px;
      display: flex;
      justify-content: space-evenly;

      img {
        width: 42px;
        vertical-align: middle;
        // margin-right: 12px;
      }

      &.active {
        background: #217eff;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
</style>

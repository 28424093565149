<template>
  <div class="soft">
    <img class="bg" src="~@/assets/aboutus/honor-bg.png" alt="">
    <el-carousel height="272px" :interval="5000" indicator-position="none" arrow="always">
      <el-carousel-item class="el-car-item" v-for="(item,index) in imgList" :key="index">
        <div class="item-box" v-for="(imgList,index2) in item" @click="showImgViewer(imgList.value)" :key="index2">
          <img class="swiper-item-img" :src="imgList.path"/>
          <div class="info-box">
            <div class="certificate-date">{{ imgList.date }}</div>
            <div class="certificate-title">{{ imgList.title }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <image-viewer
        v-if="imgViewerVisible"
        :on-close="closeImgViewer"
        :url-list="checkImgList"
        :initialIndex="initialIndex"
    />
  </div>
</template>

<script>
export default {
  name: "soft",
  components: {
    "image-viewer": () => import("@/components/ImageViewer/imageViewer.vue")
  },
  data() {
    return {
      imgList: [
        {
          value: 1,
          date: "2011.1",
          title: "宾馆办公软件计算机软件著作权登记证书",
          path: require('@/assets/aboutus/soft1.jpg')
        },
        {
          value: 2,
          date: "2011.1",
          title: "社区软件计算机软件著作权登记证书",
          path: require('@/assets/aboutus/soft2.jpg')
        },
        {value: 3, date: "2015.8", title: "物流平台软件著作权", path: require('@/assets/aboutus/soft3.jpg')},
        {value: 4, date: "2016.8", title: "服务外包考试系统后台管理系统", path: require('@/assets/aboutus/soft4.jpg')},
        {value: 5, date: "2016.8", title: "服务外包考试系统机构管理系统", path: require('@/assets/aboutus/soft5.jpg')},
        {value: 6, date: "2016.8", title: "电子商务考试系统后台管理", path: require('@/assets/aboutus/soft6.jpg')},
        {value: 7, date: "2016.8", title: "电子商务考试系统机构管理", path: require('@/assets/aboutus/soft7.jpg')},
        {value: 8, date: "2016.8", title: "企业信息管理平台著作权", path: require('@/assets/aboutus/soft8.jpg')},
        {value: 9, date: "2017.8", title: "在线培训学情监督控制器软件", path: require('@/assets/aboutus/soft9.jpg')},
        {value: 10, date: "2018.3", title: "企业亩产效益综合评价系统", path: require('@/assets/aboutus/soft10.jpg')},
        {value: 11, date: "2018.3", title: "智慧红盟党建管理系统", path: require('@/assets/aboutus/soft11.jpg')},
        {
          value: 12,
          date: "2018.3",
          title: "网络经济园区公共服务管理系统",
          path: require('@/assets/aboutus/soft12.jpg')
        },
        {value: 13, date: "2020.5", title: "户籍式企业信息管理系统", path: require('@/assets/aboutus/soft13.jpg')},
        {value: 14, date: "2021.2", title: "智慧安监监管端APP软件", path: require('@/assets/aboutus/soft14.jpg')},
        {value: 15, date: "2021.2", title: "智慧安监管理服务平台", path: require('@/assets/aboutus/soft15.jpg')},
        {
          value: 16,
          date: "2021.2",
          title: "智慧安监管理数据指挥中心系统",
          path: require('@/assets/aboutus/soft16.jpg')
        },
        {value: 17, date: "2021.2", title: "智慧安监企业端APP软件", path: require('@/assets/aboutus/soft17.jpg')},
        {value: 18, date: "2021.2", title: "智慧安监检查端APP软件", path: require('@/assets/aboutus/soft18.jpg')},
        {
          value: 1,
          date: "2011.1",
          title: "宾馆办公软件计算机软件著作权登记证书",
          path: require('@/assets/aboutus/soft1.jpg')
        },
        {
          value: 2,
          date: "2011.1",
          title: "社区软件计算机软件著作权登记证书",
          path: require('@/assets/aboutus/soft2.jpg')
        },
      ],
      checkImgList: [
        require("@/assets/aboutus/soft1.jpg"),
        require("@/assets/aboutus/soft2.jpg"),
        require("@/assets/aboutus/soft3.jpg"),
        require("@/assets/aboutus/soft4.jpg"),
        require("@/assets/aboutus/soft5.jpg"),
        require("@/assets/aboutus/soft6.jpg"),
        require("@/assets/aboutus/soft7.jpg"),
        require("@/assets/aboutus/soft8.jpg"),
        require("@/assets/aboutus/soft9.jpg"),
        require("@/assets/aboutus/soft10.jpg"),
        require("@/assets/aboutus/soft11.jpg"),
        require('@/assets/aboutus/soft12.jpg'),
        require('@/assets/aboutus/soft13.jpg'),
        require('@/assets/aboutus/soft14.jpg'),
        require('@/assets/aboutus/soft15.jpg'),
        require('@/assets/aboutus/soft16.jpg'),
        require('@/assets/aboutus/soft17.jpg'),
        require('@/assets/aboutus/soft18.jpg'),
        require("@/assets/aboutus/soft1.jpg"),
        require("@/assets/aboutus/soft2.jpg"),
      ],
      imgViewerVisible: false,
      initialIndex: null
    }
  },
  methods: {
    handleData() {
      let newDataList = []
      let current = 0
      if (this.imgList && this.imgList.length > 0) {
        for (let i = 0; i <= this.imgList.length - 1; i++) {
          if (i % 4 !== 0 || i === 0) {
            if (!newDataList[current]) {
              newDataList.push([this.imgList[i]])
            } else {
              newDataList[current].push(this.imgList[i])
            }
          } else {
            current++
            newDataList.push([this.imgList[i]])
          }
        }
      }
      this.imgList = [...newDataList]
    },
    showImgViewer(value) {
      this.initialIndex = value - 1;
      this.imgViewerVisible = true;
      const m = e => {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
    closeImgViewer() {
      this.imgViewerVisible = false;
      const m = e => {
        e.preventDefault();
      };
      document.body.style.overflow = "auto";
      document.removeEventListener("touchmove", m, true);
    }
  },
  created() {
    this.handleData()
  },

}
</script>

<style lang="scss" scoped>
.soft {
  width: 1341px;
  min-height: 400px;
  margin: 0 auto;
  position: relative;
  padding-top: 100px;

  .bg {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
  }

  .swiper-item-img {
    width: 210px;
    height: 272px;
    margin-right: 40px;
  }

}

::v-deep {
  .el-car-item {
    width: 100%;
    display: flex;
    padding-left: 14%;
  }

  .el-carousel__arrow--left {
    left: 9%;
  }

  .el-carousel {
    width: 1200px;
  }

  // 点击展示大图样式
  .el-image-viewer__img {
    width: 600px !important;
    height: auto !important;
  }

  .el-image-viewer__close {
    opacity: 1;
  }

  .el-image-viewer__prev {
    left: 400px;
    opacity: 1;
  }

  .el-image-viewer__next {
    right: 400px;
    opacity: 1;
  }
}

.item-box {
  position: relative;

  .info-box {
    box-sizing: border-box;
    text-align: left;
    width: 210px;
    height: 55px;
    background-color: rgba(0, 0, 0, .4);
    color: #fff;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    padding: 5px;

  }
}

</style>
